import React, { useState } from "react";
import '../css/rutas_horario_carga.css'
import { Outlet, Link } from "react-router-dom";
import Carousel from '../components/Carousel'
import Footer from '../components/FooterCarga';
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import buque_cali from '../assets/images/iconRutasHorario/california-star.svg'
import buque_mexico from '../assets/images/iconRutasHorario/mexico-star.svg'

import Navbar from '../components/Navbar';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapaTopo from '../components/maps/MapaTopo'
import MapaMaza from '../components/maps/MapaMaza'




// Token de acceso de Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';

const servicios = [
    '../images/rutasHorariosCarga/AlimentoCompleto.svg',
    '../images/rutasHorariosCarga/boletoConductor.svg',
    '../images/rutasHorariosCarga/Cabina.svg',
    '../images/rutasHorariosCarga/panycafe.svg',
    '../images/rutasHorariosCarga/preciopreferencial.svg',
    
    // Añade más logos aquí...
  ];

export default function Rutas_horario_carga() {
    const [isRutaVisible, setIsRutaVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('La Paz - Topolobampo');
    const [cambiarMapa, setCambiarMapa] = useState(true);

    const toggleIsRutaVisible = () => {
        setIsRutaVisible(!isRutaVisible)
    };
    // Manejar la selección del usuario
    /*
    const handleRouteSelection = (route) => {
        setSelectedOption(route);
        setIsRutaVisible(!isRutaVisible)
    };
    */
    //Itinerario La Paz - Topolobampo
    const itinerario_LP_TP = [
        {
          dia: 'Lunes',
          embarque: '10:30 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Martes',
          embarque: '10:30 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '10:30 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '10:30 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '10:30 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '20:00 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viaje programado',
        }
    ];
    //Itinerario La Paz - Topolobampo
    const itinerario_TP_LP = [
        {
          dia: 'Lunes',
          embarque: '21:59 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '21:59 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '21:59 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '21:59 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '21:59 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '21:00 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',

        }
    ];
    
    const itinerario_LP_MZ = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '14:00 HRS',
          salida: '17:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miercoles',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Jueves',
          embarque: '14:00 HRS',
          salida: '17:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Sábado',
          embarque: '14:00 HRS',
          salida: '17:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        }
    ];
    const itinerario_MZ_LP = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
            dia: 'Miercoles',
            embarque: '13:00 HRS',
            salida: '16:00 HRS',
            duracion: '08:00 HRS',    
        },
        {
          dia: 'Jueves',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Viernes',
          embarque: '13:00 HRS',
            salida: '16:00 HRS',
            duracion: '08:00 HRS',      
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '13:00 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS',       
        }
    ];

        /* Function maps */
        const [map, setMap] = useState(null);
        const [markers, setMarkers] = useState([]);
    
        // Función para mostrar la ruta seleccionada
        const showRoute = (coordinates) => {
            
            if (map) {
            // Lógica para mostrar la ruta en el mapa
            map.getSource('route').setData({
                type: 'FeatureCollection',
                features: [
                {
                    type: 'Feature',
                    geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                    }
                }
                ]
            });
        
             // Agregar marcadores solo en la primera y última coordenada
             const startMarker = new mapboxgl.Marker().setLngLat(coordinates[0]).addTo(map);
             const endMarker = new mapboxgl.Marker().setLngLat(coordinates[coordinates.length - 1]).addTo(map);
     
             // Guardar los marcadores para poder limpiarlos más tarde
             setMarkers([startMarker, endMarker]);
    
            }
        };
    
        // Limpiar marcadores
        const clearMarkers = () => {
            markers.forEach(marker => marker.remove());
            setMarkers([]);
        };
    
        // Manejar la selección del usuario
        const handleRouteSelection = (route) => {
            clearMarkers(); // Limpiar los marcadores anteriores
            setSelectedOption(route);
            setIsRutaVisible(!isRutaVisible);
    
            switch (route) {
                case 'La Paz - Topolobampo': 
                    setCambiarMapa(false);       
                    showRoute([
                        [-110.327473, 24.275435],
                        [-110.327650, 24.274789],
                        [-110.327678, 24.273909],
                        [-110.327487, 24.267109],
                        [-110.327510, 24.259804],
                        [-110.328043, 24.257397],
                        [-110.336970, 24.246213],
                        [-110.338420, 24.245689],
                        [-110.341144, 24.246395],
                        [-110.345243, 24.250474],
                        [-110.353965, 24.266607],
                        [-110.357039, 24.289822],
                        [-110.359388, 24.309069],
                        [-110.350219, 24.354059],
    
                        [-110.348830, 24.358184],
                        [-110.344600, 24.363167],
                        [-110.340006, 24.366954],
                        [-110.328264, 24.373530],
                        [-110.166521, 24.480128],
                        [-110.076424, 24.571724],
                        [-109.968686, 24.686899],
                        [-109.191069, 25.508345],
                        [-109.166063, 25.527144],
                        [-109.134978, 25.551045],
                        [-109.131329, 25.553301],
                        [-109.128356, 25.554276],
                        [-109.115381, 25.555861],
                        [-109.095109, 25.558970],
                        [-109.062628, 25.575868],
                        [-109.056119, 25.579721],
                        [-109.055033, 25.582511],
                        [-109.054471, 25.585714],
                        [-109.054762, 25.588164],
                        [-109.054936, 25.588794],
                        [-109.055790, 25.589529],
                        [-109.058662, 25.590877],
    
                    ]);
                    break;
                case 'La Paz - Mazatlan':
                    setCambiarMapa(false);       
                    showRoute([ 
                        [-110.327458, 24.275424],
                        [-110.328072, 24.275111],
                        [-110.328366, 24.274641],
                        [-110.328538, 24.274014],
                        [-110.328597, 24.273084],
                        [-110.328597, 24.259600],
                        [-110.328811, 24.258258],
                        [-110.330110, 24.255444],
                        [-110.331653, 24.253772],
                        [-110.332387, 24.253132],
                        [-110.333731, 24.252463],
                        [-110.334770, 24.252059],
                        [-110.336924, 24.251766],
                        [-110.338910, 24.252003],
                        [-110.342073, 24.253299],
                        [-110.343020, 24.253995],
                        [-110.344059, 24.255346],
                        [-110.347146, 24.261782],
                        [-110.356251, 24.309549],
                        [-110.356099, 24.317430],
                        [-110.354051, 24.327844],
                        [-110.344762, 24.353541],
                        [-110.343158, 24.356728],
                        [-110.340851, 24.359052],
                        [-110.321829, 24.371133],
                        [-110.318101, 24.372859],
                        [-110.093249, 24.449086],
                        [-110.081607, 24.451826],
                        [-110.069033, 24.453842],
                        [-110.026880, 24.453216],
                        [-109.636015, 24.463647],
                        [-109.625122, 24.462034],
                        [-109.617681, 24.458877],
                        [-109.596337, 24.444743],
                        [-109.004936, 23.983067],
                        [-108.995515, 23.977638],
                        [-108.983254, 23.972074],
                        [-107.795299, 23.538176],
                        [-106.498322, 23.160360],
                        [-106.436466, 23.167502],
                        [-106.420658, 23.177721],
                        [-106.417849, 23.182754],
                        [-106.417591, 23.183524],
                        [-106.417545, 23.184760],
                        [-106.417766, 23.186047],
                        [-106.418135, 23.186411],
                        [-106.418890, 23.186403],
                        [-106.419784, 23.186174],
                        [-106.420217, 23.186234],
    
                    ]);
                    break;
                case 'Topolobampo - La Paz':    
                    setCambiarMapa(false);           
                    showRoute([
                        [-110.327473, 24.275435],
                        [-110.327650, 24.274789],
                        [-110.327678, 24.273909],
                        [-110.327487, 24.267109],
                        [-110.327510, 24.259804],
                        [-110.328043, 24.257397],
                        [-110.336970, 24.246213],
                        [-110.338420, 24.245689],
                        [-110.341144, 24.246395],
                        [-110.345243, 24.250474],
                        [-110.353965, 24.266607],
                        [-110.357039, 24.289822],
                        [-110.359388, 24.309069],
                        [-110.350219, 24.354059],
    
                        [-110.348830, 24.358184],
                        [-110.344600, 24.363167],
                        [-110.340006, 24.366954],
                        [-110.328264, 24.373530],
                        [-110.166521, 24.480128],
                        [-110.076424, 24.571724],
                        [-109.968686, 24.686899],
                        [-109.191069, 25.508345],
                        [-109.166063, 25.527144],
                        [-109.134978, 25.551045],
                        [-109.131329, 25.553301],
                        [-109.128356, 25.554276],
                        [-109.115381, 25.555861],
                        [-109.095109, 25.558970],
                        [-109.062628, 25.575868],
                        [-109.056119, 25.579721],
                        [-109.055033, 25.582511],
                        [-109.054471, 25.585714],
                        [-109.054762, 25.588164],
                        [-109.054936, 25.588794],
                        [-109.055790, 25.589529],
                        [-109.058662, 25.590877],
    
                    ]);
                    break;
                case 'Mazatlan - La Paz':
                    setCambiarMapa(false);       
                    showRoute([ 
                        [-110.327458, 24.275424],
                        [-110.328072, 24.275111],
                        [-110.328366, 24.274641],
                        [-110.328538, 24.274014],
                        [-110.328597, 24.273084],
                        [-110.328597, 24.259600],
                        [-110.328811, 24.258258],
                        [-110.330110, 24.255444],
                        [-110.331653, 24.253772],
                        [-110.332387, 24.253132],
                        [-110.333731, 24.252463],
                        [-110.334770, 24.252059],
                        [-110.336924, 24.251766],
                        [-110.338910, 24.252003],
                        [-110.342073, 24.253299],
                        [-110.343020, 24.253995],
                        [-110.344059, 24.255346],
                        [-110.347146, 24.261782],
                        [-110.356251, 24.309549],
                        [-110.356099, 24.317430],
                        [-110.354051, 24.327844],
                        [-110.344762, 24.353541],
                        [-110.343158, 24.356728],
                        [-110.340851, 24.359052],
                        [-110.321829, 24.371133],
                        [-110.318101, 24.372859],
                        [-110.093249, 24.449086],
                        [-110.081607, 24.451826],
                        [-110.069033, 24.453842],
                        [-110.026880, 24.453216],
                        [-109.636015, 24.463647],
                        [-109.625122, 24.462034],
                        [-109.617681, 24.458877],
                        [-109.596337, 24.444743],
                        [-109.004936, 23.983067],
                        [-108.995515, 23.977638],
                        [-108.983254, 23.972074],
                        [-107.795299, 23.538176],
                        [-106.498322, 23.160360],
                        [-106.436466, 23.167502],
                        [-106.420658, 23.177721],
                        [-106.417849, 23.182754],
                        [-106.417591, 23.183524],
                        [-106.417545, 23.184760],
                        [-106.417766, 23.186047],
                        [-106.418135, 23.186411],
                        [-106.418890, 23.186403],
                        [-106.419784, 23.186174],
                        [-106.420217, 23.186234],
    
                    ]);
                    break;
                default:
                    break;
            }
        };
    
        // Crear el mapa cuando el componente se monte    
        React.useEffect(() => {
            const initializeMap = () => {
                const mapInstance = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [-108.931, 25.177],
                    zoom: 5.5
                });
    
                // Agregar la fuente y la capa para mostrar la ruta
                mapInstance.on('load', () => {
                    mapInstance.addSource('route', {
                        type: 'geojson',
                        data: {
                        type: 'FeatureCollection',
                        features: []
                        }
                    });
    
                    mapInstance.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                        },
                        paint: {
                        'line-color': '#888',
                        'line-width': 8
                        }
                    });
    
                });
    
                setMap(mapInstance); 
                 // Mostrar la ruta por defecto
                    
            };
    
            // Inicializar el mapa
            initializeMap();
    
            // Limpiar el mapa cuando el componente se desmonte
            return () => map?.remove();
    
        }, []);
    
     
  return (
    <>
       <Navbar />
        <section>
            <div className='header_section carga'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Rutas y Horarios</h3>
                        <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                    </div>                
                </div>
            </div>
        </section>

        <section className="d-none d-sm-none d-md-block">
            <div className="rutas_horarios carga">
                <div className="container">   

                    <div className="rutas_horario_tabs">
                        <div className='select_route_ruta_horario'>           
                            <label>Selecciona una ruta</label>
                            <div className='box_select_route' onClick={toggleIsRutaVisible} >                    
                                <div className='select_ruta__horario'>                               
                                        <label>{selectedOption} </label>
                                        <i class="fi fi-br-angle-small-down"></i>
                                    </div>                                                      
                                <div>
                                    {isRutaVisible && 
                                    <div className='optionListRoutes'>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                                    </div>
                                    }
                                </div>
                            </div>                              
                        </div>                                                   
                    </div>

                    <div className="container_rutas_horarios">
                        <div className="content_rutas_horarios">
                            <div className="box_rutas_horarios">
                                <div className="header_rutas_horarios">
                                    <div className="box_selected_rutas_horarios">
                                        <label></label>
                                        
                                    </div>
                                    <div className="title_rutas_horarios">
                                        <div className="item_rutas_horarios"><label>Registro:</label></div>
                                        <span></span>
                                        <div className="item_rutas_horarios"><label>Salida:</label></div>
                                        <span></span>
                                        <div className="item_rutas_horarios"><label>Llegada:</label> </div>  
                                    </div>
                                </div>
                                <div className="body_rutas_horarios">
                                    {selectedOption === 'La Paz - Topolobampo' && (
                                    <>                                
                                        <div className="box_dias_itinerario">
                                            <div className="item_dia_itinerario"><h2>LUNES A VIERNES</h2></div>
                                            
                                            <div className="item_dia_itinerario"><h2>SÁBADO</h2></div>
                                                
                                            <div className="item_dia_itinerario"><h2>DOMINGO</h2> </div>  
                                        </div>
                                        <div className="box_items_itinerario">
                                            {itinerario_LP_TP
                                            .filter(viaje => viaje.dia === 'Lunes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                            {itinerario_LP_TP
                                            .filter(viaje => viaje.dia === 'Sabado')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                            {itinerario_LP_TP
                                            .filter(viaje => viaje.dia === 'Domingo')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                        
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    )}
                                    {selectedOption === 'Topolobampo - La Paz' && (
                                    <>                                
                                        <div className="box_dias_itinerario">
                                            <div className="item_dia_itinerario"><h2>LUNES A VIERNES</h2></div>
                                            
                                            <div className="item_dia_itinerario"><h2>SÁBADO</h2></div>
                                                
                                            <div className="item_dia_itinerario"><h2>DOMINGO</h2> </div>  
                                        </div>
                                        <div className="box_items_itinerario">
                                            {itinerario_TP_LP
                                            .filter(viaje => viaje.dia === 'Lunes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                            {itinerario_TP_LP
                                            .filter(viaje => viaje.dia === 'Sabado')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                         
                                                </div>
                                            ))}
                                            {itinerario_TP_LP
                                            .filter(viaje => viaje.dia === 'Domingo')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    )}
                                    {selectedOption === 'La Paz - Mazatlan' && (
                                    <>                                
                                        <div className="box_dias_itinerario">
                                            <div className="item_dia_itinerario"><h2>MARTES, JUEVES Y SÁBADO</h2></div>
                                            
                                            <div className="item_dia_itinerario"><h2>LUNES, MIÉRCOLES, VIERNES Y DOMINGO</h2></div>
                                                
                                        </div>
                                        <div className="box_items_itinerario">
                                            {itinerario_LP_MZ
                                            .filter(viaje => viaje.dia === 'Martes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                            {itinerario_LP_MZ
                                            .filter(viaje => viaje.dia === 'Viernes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                         
                                                </div>
                                            ))}
                                        
                                        </div>
                                    </>
                                    )}
                                    {selectedOption === 'Mazatlan - La Paz' && (
                                    <>                                
                                        <div className="box_dias_itinerario">
                                            <div className="item_dia_itinerario"><h2>MIÉRCOLES, VIERNES Y DOMINGO</h2></div>
                                            
                                            <div className="item_dia_itinerario"><h2>LUNES, MARTES, JUEVES, Y SÁBADO</h2></div>
                                                
                                        </div>
                                        <div className="box_items_itinerario">
                                            {itinerario_MZ_LP
                                            .filter(viaje => viaje.dia === 'Viernes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_hora_itinerario"><label> {viaje.embarque}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.salida}</label></div>
                                                    
                                                    <div className="item_hora_itinerario"><label>{viaje.duracion}</label> </div>                                                        
                                                </div>
                                            ))}
                                            {itinerario_MZ_LP
                                            .filter(viaje => viaje.dia === 'Lunes')
                                            .map((viaje, index) => (
                                                <div key={index} className="items_box_itinerario">
                                                    <div className="item_itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                         
                                                </div>
                                            ))}
                                        
                                        </div>
                                    </>
                                    )}
                                </div>
                            </div>
                            <div className="box_servicios_itinerario">                            
                                <div className="available_services">                                
                                    <h3>Servicios disponibles</h3>
                                    <Carousel logos={servicios} />                                                                                                     
                                </div>
                                <div className="box_buque_itinerario">
                                    <div className="available_services_buque">
                                        <div><p>Esta ruta generalmente será a bordo del</p></div>
                                        {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ?(
                                        <>
                                            <div className="box_available_services">
                                                <img src={buque_cali} alt="buque california star" />
                                                <h4>CALIFORNIA STAR</h4>
                                            </div>
                                            <div><p>Conoce más de <Link to="/servicios-california-star">nuestro buque</Link></p></div>
                                        </>):(<>
                                            <div className="box_available_services"><img src={buque_mexico} alt="buque california star" /><h4>MÉXICO STAR</h4></div>
                                            <div><p>Conoce más de <Link to="/servicios-mexico-star">nuestro buque</Link></p></div>
                                        </> )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content_mapa_rutas_horarios">
                            <div className="content_mapa">

                                {/*<div id="map" style={{ width: '100%', height: '500px' }} />  */}
                                {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ?(
                                
                                    <MapaTopo />
                                ):(
                                    <MapaMaza/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="d-block d-sm-block d-md-none">
            <div className="container-rutas-horario">
                <div className="container">
                    
                    <div className="box-select-ruta">
                        <div className="box_select_rutas_horarios phone">               
                            <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                <div className='select_ruta_horario phone'>                    
                                    <label>{selectedOption} </label>
                                    <i class="fi fi-br-angle-small-down"></i>
                                </div>      
                            </div>
                            {isRutaVisible && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazátlan</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                            </div>
                            }                                                                                                                                                                                                                
                        </div>
                    </div>

                    <div className="content-rutas-horario">
                        <div className="body-rutas-horario">
                            {selectedOption === 'La Paz - Topolobampo' &&(
                            <>                                
                                                    
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES A VIERNES</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>SÁBADO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>DOMINGO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                             
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'Topolobampo - La Paz' &&(
                            <>                                
                                                    
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES A VIERNES</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>SÁBADO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>DOMINGO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                       
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'La Paz - Mazatlan' &&(
                            <>                                
                                                    
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Martes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>MARTES, JUEVES Y SÁBADO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES, MIÉRCOLES, VIERNES Y DOMINGO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                               
                            </>
                            )}
                            {selectedOption === 'Mazatlan - La Paz' &&(
                            <>                                
                                                    
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Viernes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>MIÉRCOLES, VIERNES Y DOMINGO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES, MARTES, JUEVES Y SÁBADO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                               
                            </>
                            )}
                       
                            <div>
                                <p>• Itinerario sujeto a cambios sin previo aviso.</p>
                            </div>
                        </div>                                                                        
                    </div>
                </div>
                <div className="box-servicio-horario">
                    <div className="available-services">                                
                        <h3>Servicios disponibles</h3>
                        <Carousel logos={servicios} />                                                                                                     
                    </div>
                </div>
             
               
               
                <div className="container">
                    <div className="content-rutas-horario">
                        <div className="content_mapa">
                            <div id="map" style={{ width: '100%', height: '500px' }} />  
                        </div>
                    </div>                    
                </div>
            </div>
        </section>      

    <section className="callToAction carga">
        <div className='container'>
            
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Contacta a nuestro equipo y asegura tu próximo viaje hoy mismo. Estamos aquí para ayudarte a planificar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                           {/*} <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>     */}
                           <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a> 
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Conoce nuestras soluciones logísiticas</h4>
                            </div>
                            <div>
                                <p>Descubre cómo nuestras distintas soluciones pueden optimizar tus operaciones. Junto a ti, llevamos tu logística al siguiente nivel.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/soluciones-logisticas">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Contacta un asesor</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros; nuestro equipo de expertos esta listo para brindarte la mejor asesoria para tu proyecto.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>                  
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />
        <Footer />
        
        


        
        
        </>
  )
}
