import React from 'react'
import '../../css/promocion.css'
import promo3 from '../../assets/images/promociones/inapam-banner.webp'
import lateralPromo1 from '../../assets/images/promociones/transportamas.webp'
import lateralPromo2 from '../../assets/images/promociones/promocion_ninos-cuadrado.webp'
import bfIso from '../../assets/images/bf-iso-blanco.png'
import Temporizador from '../Temporizador'
import { Outlet, Link } from "react-router-dom";
import whatsapp from '../../assets/images/chat.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'

export default function Promo4() {
    const targetDate = new Date('2023-12-31T23:59:59').getTime();
  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones para viajar</h3>
                    <p>¡Obtén 50% de descuento sobre la tarifa publicada en cualquiera de nuestras rutas!</p>
                </div>                
            </div>
        </div>
    </section>   
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo3} alt='buen-fin-bajaferries' />
        </div>
    </section>

    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        <p></p>
                        <p>En Baja Ferries, creemos que nunca es tarde para vivir nuevas aventuras. Viaja de manera segura y disfruta de cada momento del trayecto. Si tienes 60 años o más, obtén 50% de descuento en tu próximo viaje con nosotros. </p>
                        <p>Válido hasta el 31 de diciembre.</p>
                        <div className='content_promo_btn'>                                                
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a> 
                            {/*<a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5218003377437?text=Me%20interesa%20una%20cotización'>Contactar por WhatsApp</a>*/}
                        </div> 
                    </div>
                    
                    <div className='content_promo condiciones-promo'>
                        <h4>Términos y condiciones</h4>
                        {/*<p>El tiempo se acaba, ¡Lugares limitados hasta agotar existencias!.</p>*/}
                        <ul>                            
                            <li>• Descuento sobre la tarifa publicada</li>                           
                        </ul>
                    </div>
                    {/*<div className='content_promo btn-termino-condiciones'>
                    <Link to="/condiciones-de-servicio">Consulta términos y condiciones</Link> 
                    </div>*/}
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                        {/*}
                        <div className='content-isotipo'>
                            <img src={bfIso} alt='bf-isotipo'/>
                        </div>
                        <div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p>

                        </div>
                        <div>
                            <Temporizador targetDate={targetDate} />
                        </div>
                        */}
                        <h4>Otras promociones</h4>
                        <div className='lateral-promo'>
                            <Link to="/transportando-carga">
                                <img src={lateralPromo1} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-ninos">
                                <img src={lateralPromo2} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Inicia tu viaje</h4>
                            </div>
                            <div>
                                <p>Si tienes 60 años o más, comunícate con nuestro equipo para brindarte más información sobre nuestros servicios y asegurárte una mejor experiencia de viaje </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            {/*<a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>          */}
                            <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>  
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Documentación para viajar</h4>
                            </div>
                            <div>
                                <p>Consulta los requisitos para viajar y asegúrante de tener todo listo para tu próximo viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/antes-de-viajar">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Más información</Link>                     
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />

    <Footer />
   
   </>
  )
}
