import React, {useEffect, useState} from 'react'
import '../css/soluciones.css'
import '../css/promociones.css'
import carga from '../assets/video/carga.mp4'
import ImageSlider from '../components/sliders/SliderCarga';
import Navbar from '../components/Navbar';
import { Outlet, Link } from "react-router-dom";
import '../css/navbar.css'
import descuento15 from '../assets/images/sorteo/descuento15.png'
import sorteo_mobile from '../assets/images/sorteo/descuento15cel.png'
import heroCarga from '../assets/images/carga/imageBG.webp'
import FooterCarga from '../components/FooterCarga' 

import stock from '../assets/images/soluciones/precargada.jpg'
import integracion from '../assets/images/soluciones/integracion.jpg'
import maritimo from '../assets/images/soluciones/maritimo.jpg'
import terrestre from '../assets/images/soluciones/terrestre.jpg'
import multimodal from '../assets/images/soluciones/multimodal.jpg'
import ruta from '../assets/images/ruta.png'
import tarifa from '../assets/images/tarifa.png'
import '../css/callToAction_carga.css'
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'


export default function SolucionesLogisticas() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
      
  return (
    <>
    <Navbar />
    <section id='soluciones'>
    <section>
        <div className='header_soluciones'>
        
        {!isMobile ? (
            <video muted loop autoPlay={true} playsinline preload={false} width="750" height="500" >
                <source src={carga} type="video/mp4" /> 
            </video>
        ) : (
            <div className='heroImg_carga'>
                <img src={heroCarga} alt='baja ferries hero' />
            </div>
        )}
            
           
            <div className='header_title_soluciones '>
                <div className='container'>
                    <div className='title_soluciones'>
                        <h2>Soluciones logísticas</h2>
                        <p>Ofrecemos soluciones logísticas integrales y multimodales para el traslado de todo tipo de mercancías a cualquier destino. </p>            
                    </div>                    
                </div>                               
            </div>
        </div>
    </section>
    <section className='d-none d-sm-none d-md-block'>

        <div className='sorteo'>
            <div className='container'>
                <div className='sorteo_content'>  
                    <Link to="/transportando-carga">                                  
                        <img src={descuento15} alt='transportando carga baja ferries'/>  
                    </Link>                
                </div>
            </div>
        </div>
      </section>
      <section className="d-block d-sm-block d-md-none">
        <div className='box_sorteo'>
            <Link to="/transportando-carga"> 
                <img src={sorteo_mobile} alt='sorteo baja ferries' />
            </Link> 
        </div>        
      </section>
      


      {/* Rutas, Horarios y Tarifas */}
      <section className='cta_carga'>
        <div className='container'>
            <div className='cta_container_carga'>
                <div className='cta_box_carga ruta-horario'>
                    <div className='cta_content_carga'>
                        <div className='cta_img_carga'><div className='cta_box_img_carga'><img src={ruta}  alt='ruta y horarios'/></div></div>
                        <div className='cta_info_carga'>                            
                            <div className='cta_text_carga'>
                                <h2>Rutas y Horarios</h2>
                                <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                            </div>
                            <div className='cta_btn_carga'>                                
                                <Link to="/rutas-horario-carga"> Ir ahora</Link>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className='cta_box_carga tarifa'>
                    <div className='cta_content_carga'>
                        <div className='cta_img_carga'><div className='cta_box_img_carga'><img src={tarifa} alt='tarifa baja ferries'/></div></div>
                        <div className='cta_info_carga'>
                            <div className='cta_text_carga'>
                                <h2>Tarifas</h2>
                                <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje.</p>
                            </div>                            
                            <div className='cta_btn_carga'>
                                <Link to="/tarifas-carga"> Ir ahora</Link>
                            </div>
                        </div>
                    </div>                   
                </div>                
            </div>
        </div>
      </section>

      <section className='servicio_carga_rodada'>
        <div>
           <div className='container'>
                
                <div className='servicio_carga_rodada_content'>                
                    <div className='servicio_carga_rodada_box rodada'>
                        <h2>Transbordador/Ferry de carga rodada</h2>
                        <p style={{fontWeight: '500', lineHeight: '29px'}}>Nuestro servicio de ferry de carga rodada ofrece una solución integral para el transporte eficiente de vehículos comerciales, carga rodada y pasajeros. Con una flota moderna y segura, garantizamos un viaje cómodo y seguro para ti y tus mercancías. Estamos equipados para transportar todo tipo de carga, incluyendo carga seca, refrigerada, peligrosa, viva y otros tipos especiales. </p>
                        <div className='btn_carga_rodada'>                                                         
                        <a href="mailto:contacto.comercial@bajaferries.com.mx" target="_blank" rel="noopener noreferrer">Contactar</a>                
                        </div>
                    </div>
                   
                </div>
                
           </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box'>                    
                    <div className='box_carga_info'>
                        <div className='box_carga_info_icon'>
                            <i class="fi fi-rs-objects-column"></i>
                        </div>                        
                        <div>
                            <label>Carga Seca</label>
                            <p>Mercancías que no requieren refrigeración o control de temperatura durante su transporte. Esto incluye una amplia gama de productos como ropa, electrodomésticos, muebles, maquinaria, productos electrónicos, entre otros.</p>
                        </div>                        
                    </div>
                    <div  className='box_carga_info'>
                        <div className='box_carga_info_icon'>
                            <i class="fi fi-rr-snow-blowing"></i>
                        </div>
                        
                        <div>
                            <label>Carga Refrigerada</label>
                            <p>Mercancías que requieren un control preciso de la temperatura durante su transporte para mantener su frescura o integridad. Estas cargas suelen incluir productos perecederos como frutas, verduras, carne, pescado, lácteos, medicamentos y otros productos sensibles a la temperatura.</p>
                        </div>                        
                    </div>                    
                </div>
                <div className='servicio_carga_box'>                                        
                    <div  className='box_carga_info'>
                        <div className='box_carga_info_icon'>
                            <i class="fi fi-rr-water"></i>
                        </div>                        
                        <div>
                            <label>Carga de Fluidos</label>
                            <p>Mercancías que se transportan en forma líquida, como petróleo, productos químicos, vino, agua, jugos, entre otros. Estos productos se almacenan y transportan en tanques específicos diseñados para líquidos.</p>
                        </div>                        
                    </div>
                    <div  className='box_carga_info'>
                        <div className='box_carga_info_icon'>
                            <i class="fi fi-ss-object-intersect"></i>
                        </div>                        
                        <div>
                            <label>Carga Sobredimensionada</label>
                            <p>Estamos equipados y autorizados para llevar objetos que excedan las dimensiones estándar o el peso típico de la carga. Esto implica que nuestros ferrys tienen la capacidad estructural y los equipos necesarios para manejar cargas más grandes y pesadas de manera segura.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='services_carga'>
        <div className='container'>
            <div className='services_carga_content'>
                <Link to="/integracion-logistica"> 
                <div className='services_carga_box'>
                    <div className='services_carga_box_img'>
                        <img src={integracion} alt='servicio maritimo baja ferries' />
                    </div>                                            
                    <h3>Integración logística</h3>                    
                </div>
                </Link>
                <Link to="/maritimo"> 
                <div className='services_carga_box'>
                    <div className='services_carga_box_img'>
                        <img src={maritimo} alt='servicios terrestres baja ferries' />
                    </div> 
                    <h3>Marítimo</h3>
                </div>
                </Link>
                <Link to="/terrestre"> 
                
                <div className='services_carga_box'>
                    <div className='services_carga_box_img'>
                        <img src={terrestre} alt='' />
                    </div> 
                    <h3>Terrestre </h3>
                </div>
                </Link>
                <Link to="/multimodal"> 
                <div className='services_carga_box'>
                    <div className='services_carga_box_img'>
                        <img src={multimodal} alt='servicios multimodal baja ferries' />
                    </div> 
                    <h3>Multimodal </h3>
                </div>
                </Link>
            </div>
        </div>
    </section>
     {
        /* 
        
    <section className='block_carga'>
        <div className='container'>
            <div className='servicio_transporte'>
                <div className='text_servicio_transporte'>
                    <h2>Soluciones integrales</h2>
                    <p>Ofrecemos una solución completa para tus necesidades de transporte y distribución. Construimos soluciones únicas con los servicios y modos que más te beneficien, ya sea terrestre, marítimo o multimodal, te brindaremos la mejor opción para tus operaciones logísticas. Desde almacenamiento hasta la planeación de rutas y entrega final, nuestro enfoque integral eficientará tu cadena de suministro,  Confía en nosotros para llevar tus productos a donde necesitan estar, de manera segura y puntual. </p>
                    
                    <div className='btn_servicio_transporte'>                                                
                        <Link to="/">Contáctanos</Link>                        
                    </div>
                </div>
                <div className='img_servicio_transporte'>
                    <ImageSlider images={images}/>
                </div>
            </div>
        </div>
    </section>
    <section className='container_service service_carga_bg1' id='maritimo'>
        <div className='container'>
            <div className='service_box'>
                <h2>Servicios Marítimos</h2>
                <p>Transportamos tanto carga rodada (como camiones, remolques o automóviles) como pasajeros. Nuestros ferries están equipados con rampas y otras instalaciones para permitir la carga y descarga eficientes de vehículos rodantes. </p>
                <p>Otros servicios marítimos:</p>
                <div className='service_box_list'>
                    <label>- Chartering</label>
                    <label>- Feedering </label>
                    <label>- Carga proyecto</label>
                </div>

                <Link to="/maritimo">Más información</Link>
            </div>
        </div>
    </section>
    <section className='container_service service_carga_bg2'  id='terrestre'>
        <div className='container'>
            <div className='service_content_carga'>
                <div className='service_box'>
                    <h2>Servicios Terrestres</h2>
                    <p>Ofrecemos soluciones logísticas integrales para el manejo de mercancías integrando los medios de transporte terrestre, ferroviario y marítimo en función de las necesidades de cada uno de nuestros clientes.</p>
                    <div className='service_box_list'>
                        <label>- Contract Logistics</label>
                        <label>- Freight Forwarding</label>
                        <label>- Agente Naviero</label>
                    </div>
                    <Link to="/terrestre">Más información</Link>
                </div>
            </div>
        </div>
    </section>
    <section className='container_service service_carga_bg3' id='multimodal'>
        <div className='container'>
            <div className='service_box'>                
                <h2>Soluciones Multimodales</h2>
                <p>Ofrecemos soluciones logísticas integrales para el manejo de mercancías integrando los medios de transporte terrestre, ferroviario y marítimo en función de las necesidades de cada uno de nuestros clientes.</p>
                <p>Tipo de servicio:</p>
                <div className='service_box_list'>
                    <label>- Servicio Multimodal</label>
                    <label>- Circuito Intermodal</label>                
                </div>
                <Link to="/multimodal">Más información</Link>
            </div>
        </div>
    </section>
    */
}

</section>
<section className="callToAction carga">
        <div className='container'>
            
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta rutas y horarios</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">Ver ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

    <FooterCarga />
    <Outlet />
    
    </>
  )
}
