import React from 'react'

export const Spotify = () => {
  return (
    <iframe
        title="Spotify"
        //src="https://open.spotify.com/embed/playlist/0nLiWJB8D0wilN3dE0Z2OQ"
        //https://open.spotify.com/embed/playlist/2aPmLdkJwltd6hVJTPBbcc?utm_source=generator
        src='https://open.spotify.com/embed/playlist/2aPmLdkJwltd6hVJTPBbcc'
        width="100%"
        height="420px"
        frameBorder="0"
        color='red'
        
    />
  )
}
export default Spotify;
