import React from 'react'
import '../css/aviso.css'
import Footer from '../components/Footer'
export default function Aviso() {
  return (
    <>
     <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Ajuste de itinerario</h3>
                        <p>13 al 17 de septiembre 2024</p>
                    </div>                
                </div>
            </div>
        </section>
        <section className='aviso_importante'>
            <div className='container'>
                <div>
                    <p className='aviso_fecha'>La Paz, Baja California Sur a 13 de septiembre de 2024 </p>
                    <p className='aviso_clientes'>Estimados clientes:</p>
                    <p>Por disposición de las autoridades portuarias, los puertos de Topolobampo y Mazatlán
permanecerán cerrados temporalmente debido a la presencia de la tormenta tropical "Ileana". En
consecuencia, los viajes programados en ambas rutas del 13 al 17 de septiembre se han
ajustado quedando de la siguiente manera:</p>

                    <label>Ruta: La Paz-Topolobampo-La Paz</label>

                        <table class="table table-bordered mascota-tabla">
                            <tbody>            
                                <tr>                                    
                                    <th colSpan={1} className="">Día</th>
                                    <th colSpan={1} className="">Ruta</th>
                                    <th colSpan={1} className="">Salida</th>                                    
                                    <th colSpan={1} className="">Estatus</th>   
                                </tr>
                                <tr>
                                    
                                    <td  >Viernes 13 de septimebre</td>
                                    <td>La Paz- Topolobampo</td>
                                    <td className="">13:30 horas</td>
                                    <td className="">Reprogramado</td>                                                                    
                                </tr>
                                <tr>
                                    <td>Viernes 13 de septiembre </td>
                                    <td>Topolobampo-La Paz</td>
                                    <td className="">23: 59 horas</td>
                                    <td className="">Reprogramado</td>                                  
                                    
                                </tr>
                                <tr>
                                    <td>Sábado 14 de septiembre</td>
                                    <td>La Paz-Topolobampo</td>
                                    <td className="">13:30 horas</td>
                                    <td className="">Ajuste de itinerario</td>                            
                                    	        					      
                                </tr>	
                                <tr>
                                    <td>Sábado 14 de septiembre</td>
                                    <td>La Paz-Topolobampo</td>
                                    <td className="">23:00 horas</td>
                                    <td className="">Reprogramado</td>                            
                                    	        					      
                                </tr>	
                                <tr>
                                    <td>Sábado 14 de septiembre</td>
                                    <td>Topolobampo-La Paz</td>
                                    <td className="">23:59 horas</td>
                                    <td className="">Viaje extra</td>                            
                                    	        					      
                                </tr>	
                                <tr>
                                    <td>Sábado 15 de septiembre</td>
                                    <td>La Paz-Topolobampo</td>
                                    <td className="">13:30 horas</td>
                                    <td className="">Viaje extra</td>                            
                                    	        					      
                                </tr>
                                <tr>
                                    <td>Sábado 15 de septiembre</td>
                                    <td>Topolobampo-La Paz</td>
                                    <td className="">23:00 horas</td>
                                    <td className="">Reprogramado</td>                            
                                    	        					      
                                </tr>
                                <tr>
                                    <td>Sábado 15 de septiembre</td>
                                    <td>Topolobampo-La Paz</td>
                                    <td className="">23:59 horas</td>
                                    <td className="">Ajuste de itinerario</td>                            
                                    	        					      
                                </tr>
                                <tr>
                                    <td>Sábado 16 de septiembre</td>
                                    <td>La Paz-Topolobampo</td>
                                    <td className="">13:30 horas</td>
                                    <td className="">Itinerario regular</td>                            
                                    	        					      
                                </tr>
                                <tr>
                                    <td>Sábado 16 de septiembre</td>
                                    <td>Topolobampo-La Paz</td>
                                    <td className="">23:59 horas</td>
                                    <td className="">Itinerario regular</td>                            
                                    	        					      
                                </tr>	
                             

                              
                                			                                            
                            </tbody>
                        </table>

                        <label style={{margin: '1rem 0rem'}}>Ruta: La Paz-Mazatlán-La Paz</label>

<table class="table table-bordered mascota-tabla">
    <tbody>            
        <tr>                                    
            <th colSpan={1} className="">Día</th>
            <th colSpan={1} className="">Ruta</th>
            <th colSpan={1} className="">Salida</th>                                    
            <th colSpan={1} className="">Estatus</th>   
        </tr>
        <tr>
            
            <td  >Viernes 13 de septimebre</td>
            <td>Mazatlán-La Paz</td>
            <td className="">16:00 horas</td>
            <td className="">Reprogramado</td>                                                                    
        </tr>
        
        <tr>
            <td>Sábado 14 de septiembre</td>
            <td>Mazaltán-La Paz</td>
            <td className="">16:00 horas</td>
            <td className="">Ajuste de itinerario</td>                            
                                                  
        </tr>	
        <tr>
            <td>Sábado 14 de septiembre</td>
            <td>La Paz-Mazatlán</td>
            <td className="">17:00 horas</td>
            <td className="">Reprogramado</td>                            
                                                  
        </tr>	
     	
        <tr>
            <td>Domingo 15 de septiembre</td>
            <td>Mazatlán-La Paz</td>
            <td className="">16:00 horas</td>
            <td className="">Reprogramado</td>                            
                                                  
        </tr>


        <tr>
            <td>Domingo 15 de septiembre</td>
            <td>La Paz-Mazatlán</td>
            <td className="">17:00 horas</td>
            <td className="">Ajuste de itinerario</td>                            
                                                  
        </tr>
      
        <tr>
            <td>Lunes 16 de septiembre</td>
            <td>Mazatlán-La Paz</td>
            <td className="">16:00 horas</td>
            <td className="">Ajuste de itinerario</td>                            
                                                  
        </tr>
        <tr>
            <td>Martes 17 de septiembre</td>
            <td>La Paz-Mazatlán</td>
            <td className="">17:00 horas</td>
            <td className="">Itinerario regular</td>                            
                                                  
        </tr>	
        <tr>
            <td>Miércoles 18 de septiembre</td>
            <td>Mazatlán-La Paz</td>
            <td className="">16:00 horas</td>
            <td className="">Itinerario regular</td>                            
                                                  
        </tr>	
     
     

      
                                                                
    </tbody>
</table>


                </div>
            </div>

        </section>
        <section className='aviso_contacto'>
            <div className='container'>
                A partir del lunes 16 de septiembre la ruta Topolobampo retoma su itinerario regular. Para la ruta
                de Mazatlán, lo hará el 17 de septiembre. Por seguridad nuestro punto de venta en Cabo San
                Lucas permanecerá cerrado hasta nuevo aviso.

                Ponemos a su disposición nuestros canales oficiales de comunicación para consultar la
                trayectoria del fenómeno y las últimas actualizaciones de nuestros itinerarios:
                <br />
                <ul style={{marginTop:'1rem'}}>
                    <li> • Página web: <a href="https://www.bajaferries.com.mx/" target="_blank" rel="noopener noreferrer">www.bajaferries.com.mx</a></li>
                    <li>• Redes sociales: @bajaferriesoficial</li>
                    <li>• Contac Center: <a href="tel:8003377437" >800 337 7437</a> </li>
                    <li>• Correo: <a href="mailto:contaccenter@bajaferries.com.mx">contaccenter@bajaferries.com.mx</a></li>
                
                </ul>

                <p>Para Baja Ferries la seguridad de nuestros clientes, proveedores, y colaboradores es primero.
                Gracias por permanecer atentos. <br />
                </p>

                <p>Atentamente,<br />
                Baja Ferries</p>

            </div>
        </section>
        <Footer />

    </>
  )
}

